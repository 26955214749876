<template>
  <div class="history-order-price-check">
    <div class="container">

      <div class="price-check-desc fs-16px">
        <span>在按下按鈕前，請確認以下內容無誤，送出後無法修改。</span>
      </div>
      <div class="price-check-info fs-16px" v-if="!isNeedBargain">
        <div class="agree-consign d-flex align-items-center">
          <b-form-checkbox v-model="agreeConsign">我同意</b-form-checkbox>
          <div class="agree-consign-link" @click="openConfirmAgreeConsignModal">{{ orderData.event.type === 0 ? '委託約定' : '捐贈約定' }}</div>
        </div>
        <b-form-group>
          <template v-slot:label>
            <div class="fs-16px">
              <span>契約人</span>
            </div>
          </template>
          <b-form-input v-model="orderData.contactName" disabled></b-form-input>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            <div class="label-name fs-16px">
              <span>契約人身分證字號</span>
            </div>
          </template>
          <b-form-input v-model="contractorID"></b-form-input>
        </b-form-group>
        <hr v-if="orderData.event.type === 0">
        <div class="reward-info" v-if="orderData.event.type === 0">
          <div class="title">回饋金匯款資料</div>
          <div class="detail d-flex">
            <div class="name">
              <div class="item">銀行代號</div>
              <div class="item">戶名</div>
              <div class="item">帳號</div>
            </div>
            <div class="data">
              <div class="item">{{ userBankAccount.bankCode ? `${userBankAccount.bankCode} - ${userBankAccount.bankName}` : '-' }}</div>
              <div class="item">{{ userBankAccount.accountName ? userBankAccount.accountName : '-' }}</div>
              <div class="item">{{ userBankAccount.bankAccount ? userBankAccount.bankAccount : '-' }}</div>
            </div>
          </div>
          <b-button class="reward-info-btn fs-16px" variant="primary" block  @click="openEditRewardInfoModal">
            <span>填寫銀行資料</span>
          </b-button>
        </div>
      </div>
      <div class="price-check-count fs-h3">
        <span>{{ isNeedBargain ? '同意與待議價共' : '同意 共' }}</span>
        <span v-text="itemLength"></span>
        <span>件</span>
      </div>
      <div class="order-price-check-item-list" v-if="orderData">
        <OrderPriceCheckItem v-for="(item, index) in filteredItemList" :key="index" :itemData="item" :priceDisplayType="orderData.event.priceDisplayType" :hide-edit-btn="true" @updateConfirm="updateConfirm" />
      </div>
    </div>

    <CustomModalFooter class="form-footer">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center flex-nowrap">
          <b-button variant="primary" block :disabled="showConfirmBtn || (!isNeedBargain && !isBankAccountComplete && !( this.agreeConsign && this.isValidContractorID ) ) || ( orderData.event.type === 1 && !( this.agreeConsign && this.isValidContractorID ) )" @click="checkConfirm">
            <span>{{ orderData.event.type === 1 ? '確認捐贈' : isNeedBargain ? '確認報 (議) 價' : '確認報價' }}</span>
          </b-button>

        </div>
      </div>
    </CustomModalFooter>

    <transition name="fade">
      <FullScreenModal v-if="showConfirmAgreeConsign" :title="orderData.event.type === 0 ? '委託約定': '捐贈約定'" @back="closeConfirmAgreeConsignModal">
        <div class="agree-consign-modal" v-if="orderData.event.type === 0">
          <div class="print-btn" @click="printContent">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C12.5523 2 13 2.44772 13 3V13.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858V3C11 2.44772 11.4477 2 12 2ZM5 17C5.55228 17 6 17.4477 6 18V20H18V18C18 17.4477 18.4477 17 19 17C19.5523 17 20 17.4477 20 18V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V18C4 17.4477 4.44772 17 5 17Z" fill="#404040"/>
            </svg> -->

            <Icon name="download" size="24" />
          </div>
          <p class="fs-16px">
            <span>甲方：人人共創數位科技有限公司</span><br>
            <span>乙方：{{ businessData.name }}</span><br>
            <span>丙方：{{ orderData.contactName }}</span>
          </p>
          <p class="fs-16px">
            為丙方委託乙方寄賣二手商品，並由甲方提供乙、丙方溝通平台乙事，三方議定下列條文，以資遵守：
          </p>
          <p class="fs-16px">
            <span>壹、寄賣期間及方式：</span><br>
            <span>ㄧ、丙方同意自簽定本契約書時起算{{ orderData.event.consignDayLimit }}天為止，提供二手商品予乙方寄賣，寄賣期間截止前，丙方不得要求取回二手商品。但寄賣期間屆滿後，若乙丙雙方未提出異議，則視同同意續約延長寄賣期間，丙方得隨時通知乙方取回其寄賣之二手商品。</span><br>
            <span>二、乙方同意於其所經營之通路，以適當之方式將丙方所提供之二手商品上架銷售。</span><br>
            <span>三、丙方應依照乙方之指示，以郵寄或親自交付之方式交付欲寄賣之二手商品予乙方。</span><br>
            <span>四、乙方有權隨時拒絕丙方欲寄賣之二手商品，若經乙方拒絕之二手商品已交付乙方，丙方同意逕由乙方捐贈至慈善機構或自行處理，惟若丙方願負擔郵資，乙方應寄還予丙方。</span>
          </p>
          <p class="fs-16px">
            <span>貳、溝通平台：</span><br>
            <span>ㄧ、乙、丙方同意使用甲方提供之「線上衣物委託管理服務系統」（下稱系爭系統）進行二手商品寄賣細節之溝通，甲方僅提供系爭系統予乙、丙雙方作為溝通平台使用，委託寄賣契約仍僅存在乙、丙雙方之間，與甲方無涉，甲方不介入乙丙雙方間之委託寄賣關係中；乙丙雙方若發生糾紛，應自行依據本契約約定解決之，不得要求甲方賠償或介入。</span><br>
            <span>二、本契約之成立不得視為甲方與乙方或丙方任一方間，成立代理、委任、僱傭、承攬、買賣、居間、行紀、寄託等關係。</span>
          </p>
          <p class="fs-16px">
            <span>參、寄賣價格及分潤：</span><br>
            <span>ㄧ、乙、丙方透過系爭系統議定寄賣商品之價格區間後，乙方僅得於價格區間內出售二手商品，未經丙方同意，不得任意變更之。</span><br>
            <span>二、乙、丙方同意依照雙方於系爭系統內議定之分潤比例，進行分潤，乙方應於二手商品售出後，依照丙方於系爭系統內提供之匯款帳號或其他付款方式，分潤予丙方。</span>
          </p>
          <p class="fs-16px">
            <span>肆、擔保：</span><br>
            <span>一、丙方保證具有充分權利提供該二手商品予乙方寄賣，絕無侵害他人之商標權等智慧財產權、所有權或其他權利之情事。</span><br>
            <span>二、丙方應提供二手商品之完整資訊（包含但不限於：品牌、使用時間、使用情形、瑕疵或損壞情形等）予乙方知悉，倘因丙方之故意或過失未告知上情，致甲方或乙方發生損害，應由丙方對甲方或乙方負損害賠償責任（包含但不限於律師費、訴訟費、與他人之和（調）解金等）。</span><br>
            <span>三、二手商品售出後，倘買家向乙方主張瑕疵擔保責任致乙方受有損害，應由丙方對乙方負賠償之責。</span><br>
            <span>四、乙、丙雙方同意於二手商品出售前，其所有權仍屬於丙方所有，乙方應盡善良管理人之注意義務進行保管。</span>
          </p>
          <p class="fs-16px">
            <span>伍、未售出商品之處理：</span><br>
            <span>乙方若未於寄賣期間內售出丙方寄賣二手商品，乙方應通知並回報予丙方，該二手商品乙方得逕為捐贈慈善公益機構或自行處理之，惟若丙方願負擔運費，乙方應依丙方之指示將二手商品寄還予丙方。</span>
          </p>
          <p class="fs-16px">
            <span>陸、契約終止：</span><br>
            <span>一、任一方違反本契約時，未違約方得以書面定7日以上之期限通知違約方改善，屆期不改善或改善不完全者，未違約方得終止本契約，未違約方並得向違約方請求賠償因違約而所受之損害（包含但不限於：律師費、訴訟費、與他人之和（調）解金等）。</span><br>
            <span>二、因不可抗力事由，如：颱風、火災、洪水、地震、暴動、戰爭、罷工、禁止出口等，致任一方無法順利履行本契約者，受影響之一方不負遲延責任，但應於不可抗力事由結束後，儘速履約。若不可抗力之事由達一個月仍無法回復正常者，任一方均得終止本契約。</span><br>
            <span>三、本契約終止或寄賣期間屆滿後，乙方應立即停止丙方二手商品之銷售活動，並於10日內完成全部產品之下架，並依第伍條約定處理。</span>
          </p>
          <p class="fs-16px">
            <span>柒、其他：</span><br>
            <span>ㄧ、關於丙方委託乙方寄賣二手商品乙事，若本契約有未盡事宜，得由乙丙雙方另透過系爭系統議定之，惟議定之內容均與甲方無涉，乙丙雙方若發生糾紛，應自行依據本契約或議定內容解決之，不得要求甲方賠償或介入。</span><br>
            <span>二、非經三方書面同意，任一方不得將本契約所生之任何權利與義務讓與第四人。</span><br>
            <span>三、因本契約所生之爭議，雙方同意以臺灣臺北地方法院為第一審管轄法院。</span><br>
            <span>四、本契約一式三份，由甲乙丙三方各執一份為憑。</span>
          </p>
          <p class="fs-16px">
            <span>捌、個人資料搜集聲明：</span><br>
            <span>一、甲方因營運及管理維護系爭系統等相關業務，而蒐集、處理或利用丙方之姓名、出生年月日、國民身分證統一編號、婚姻、家庭、教育、職業、健康檢查、犯罪前科、聯絡方式、財務情況、社會活動及其他得以直接或間接方式識別丙方之個人資料時，皆以尊重丙方的權益為基礎，並以誠實信用方式及以下原則為之。</span><br>
            <span>二、甲方蒐集丙方之個人資料之目的係為營運及管理維護系爭系統之執行（下稱「特定蒐集目的」）。丙方知悉並同意甲方在前開特定目的存續期間及依法令規定要求之期間內蒐集、處理或利用丙方的個人資料；丙方的個人資料，會以電子檔案或紙本形式於我國境內或境外供甲方及使用系爭系統之店家處理及利用，是丙方個人資料之處理及利用對象包括：甲方及受甲方委託處理事務之委外機構、與甲方有業務往來之機構、甲方之關係企業、甲方所同意之對象、使用系爭系統之店家，且丙方個人資料之利用範圍包括上述對象其國內及國外之所在地。</span><br>
            <span>三、甲方保有丙方的個人資料時，基於我國個人資料保護法之規定，丙方可以透過書面行使下述的權利，除基於符合「個人資料保護法」與其他相關法律規定外，甲方不會拒絕：</span><br>
            <span>1.查詢或請求閱覽丙方之個人資料。</span><br>
            <span>2.請求製給丙方之個人資料複製本。</span><br>
            <span>3.請求補充或更正丙方之個人資料。</span><br>
            <span>4.請求停止蒐集、處理或利用丙方之個人資料。</span><br>
            <span>5.請求刪除丙方之個人資料。</span><br>
            <span>四、甲方基於上述原因而需蒐集、處理或利用丙方的個人資料，若丙方選擇拒絕提供個人資料或是填寫不完整有所隱匿時，甲方將無法提供丙方完整之系爭系統之服務，且不負相關賠償責任。倘若丙方所提供資料有虛構不實，致造成甲方遭受損害時，丙方應負相關損害賠償責任。</span><br>
            <span>五、本條聲明明確宣示個人資料管理的重要性，甲方全體員工應確實了解此聲明內容以維護丙方所有個人資料檔案之安全與永續經營。</span><br>
            <span>六、本條聲明由甲方人人共創數位科技有限公司提出，經丙方確認並同意自本契約成立時起，甲方有權依上述聲明書內容蒐集、處理或利用丙方的個人資料，丙方並可透過下列管道聯絡甲方。</span><br>
            <span>E-Mail：service@walkingcloset.co</span>
          </p>
          <div class="fs-16px">
            立契約書時間：{{ currentDate }}
          </div>
        </div>
        <div class="agree-consign-modal" v-else>
          <div class="print-btn" @click="printContent">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C12.5523 2 13 2.44772 13 3V13.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858V3C11 2.44772 11.4477 2 12 2ZM5 17C5.55228 17 6 17.4477 6 18V20H18V18C18 17.4477 18.4477 17 19 17C19.5523 17 20 17.4477 20 18V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V18C4 17.4477 4.44772 17 5 17Z" fill="#404040"/>
            </svg> -->

            <Icon name="download" size="24" />
          </div>
          <p class="fs-16px">
            <span>甲方：人人共創數位科技有限公司</span><br>
            <span>乙方：{{ businessData.name }}</span><br>
            <span>丙方：{{ orderData.contactName }}</span>
          </p>
          <p class="fs-16px">
            為丙方委託乙方捐贈商品，並由甲方提供乙、丙方溝通平台乙事，三方議定下列條文，以資遵守：
          </p>
          <p class="fs-16px">
            <span>壹、捐贈方式：</span><br>
            <span>ㄧ、丙方同意自簽定本契約書時起算14天為止，提供捐贈商品予乙方，未經雙方同意，丙方不得要求取回商品。</span><br>
            <span>二、乙方同意遵守其提供的捐贈說明，以適當之方式處理丙方所提供之商品。</span><br>
            <span>三、丙方應依照乙方之指示，以郵寄或親自交付之方式交付欲捐贈之商品予乙方。</span><br>
            <span>四、乙方有權隨時拒絕丙方欲捐贈之商品，若經乙方拒絕之商品已交付乙方，丙方同意逕由乙方自行處理，惟若丙方願負擔郵資，乙方應寄還予丙方。</span>
          </p>
          <p class="fs-16px">
            <span>貳、溝通平台：</span><br>
            <span>ㄧ、乙、丙方同意使用甲方提供之「線上衣物委託管理服務系統」（下稱系爭系統）進行商品捐贈細節之溝通，甲方僅提供系爭系統予乙、丙雙方作為溝通平台使用，捐贈契約仍僅存在乙、丙雙方之間，與甲方無涉，甲方不介入乙丙雙方間之捐贈商品關係中；乙丙雙方若發生糾紛，應自行依據本契約約定解決之，不得要求甲方賠償或介入。</span><br>
            <span>二、本契約之成立不得視為甲方與乙方或丙方任一方間，成立代理、委任、僱傭、承攬、買賣、居間、行紀、寄託等關係。</span>
          </p>
          <p class="fs-16px">
            <span>參、捐贈分潤比例與資訊發布：</span><br>
            <span>ㄧ、丙方同意依照乙方於系爭系統內議定之捐贈分潤比例予乙方認定之慈善公益機構。</span><br>
            <span>二、乙方應依其公告之方式，定期發布捐贈流向，以確保資訊公開透明。</span>
          </p>
          <p class="fs-16px">
            <span>肆、擔保：</span><br>
            <span>一、丙方保證具有充分權利提供該商品予乙方，絕無侵害他人之商標權等智慧財產權、所有權或其他權利之情事。</span><br>
            <span>二、丙方應提供二手商品之完整資訊（包含但不限於：品牌、使用時間、使用情形、瑕疵或損壞情形等）予乙方知悉，倘因丙方之故意或過失未告知上情，致甲方或乙方發生損害，應由丙方對甲方或乙方負損害賠償責任（包含但不限於律師費、訴訟費、與他人之和（調）解金等）。</span><br>
            <span>三、商品售出後，倘買家向乙方主張瑕疵擔保責任致乙方受有損害，應由丙方對乙方負賠償之責。</span>
          </p>
          <p class="fs-16px">
            <span>伍、契約終止：</span><br>
            <span>一、任一方違反本契約時，未違約方得以書面定7日以上之期限通知違約方改善，屆期不改善或改善不完全者，未違約方得終止本契約，未違約方並得向違約方請求賠償因違約而所受之損害（包含但不限於：律師費、訴訟費、與他人之和（調）解金等）。</span><br>
            <span>二、因不可抗力事由，如：颱風、火災、洪水、地震、暴動、戰爭、罷工、禁止出口等，致任一方無法順利履行本契約者，受影響之一方不負遲延責任，但應於不可抗力事由結束後，儘速履約。若不可抗力之事由達一個月仍無法回復正常者，任一方均得終止本契約。</span><br>
            <span>三、本契約終止後，乙方應立即停止丙方商品之捐贈流程，並於10日內公布捐贈商品處理流程。</span>
          </p>
          <p class="fs-16px">
            <span>陸、其他：</span><br>
            <span>一、關於丙方委託乙方捐贈商品乙事，若本契約有未盡事宜，得由乙丙雙方另透過系爭系統議定之，惟議定之內容均與甲方無涉，乙丙雙方若發生糾紛，應自行依據本契約或議定內容解決之，不得要求甲方賠償或介入。</span><br>
            <span>二、非經三方書面同意，任一方不得將本契約所生之任何權利與義務讓與第四人。</span><br>
            <span>三、因本契約所生之爭議，雙方同意以臺灣臺北地方法院為第一審管轄法院。</span><br>
            <span>四、本契約一式三份，由甲乙丙三方各執一份為憑。</span>
          </p>
          <p class="fs-16px">
            <span>柒、個人資料搜集聲明：</span><br>
            <span>一、甲方因營運及管理維護系爭系統等相關業務，而蒐集、處理或利用丙方之姓名、出生年月日、國民身分證統一編號、婚姻、家庭、教育、職業、健康檢查、犯罪前科、聯絡方式、財務情況、社會活動及其他得以直接或間接方式識別丙方之個人資料時，皆以尊重丙方的權益為基礎，並以誠實信用方式及以下原則為之。</span><br>
            <span>二、甲方蒐集丙方之個人資料之目的係為營運及管理維護系爭系統之執行（下稱「特定蒐集目的」）。丙方知悉並同意甲方在前開特定目的存續期間及依法令規定要求之期間內蒐集、處理或利用丙方的個人資料；丙方的個人資料，會以電子檔案或紙本形式於我國境內或境外供甲方及使用系爭系統之店家處理及利用，是丙方個人資料之處理及利用對象包括：甲方及受甲方委託處理事務之委外機構、與甲方有業務往來之機構、甲方之關係企業、甲方所同意之對象、使用系爭系統之店家，且丙方個人資料之利用範圍包括上述對象其國內及國外之所在地。</span><br>
            <span>三、甲方保有丙方的個人資料時，基於我國個人資料保護法之規定，丙方可以透過書面行使下述的權利，除基於符合「個人資料保護法」與其他相關法律規定外，甲方不會拒絕：</span><br>
            <span>1.查詢或請求閱覽丙方之個人資料。</span><br>
            <span>2.請求製給丙方之個人資料複製本。</span><br>
            <span>3.請求補充或更正丙方之個人資料。</span><br>
            <span>4.請求停止蒐集、處理或利用丙方之個人資料。</span><br>
            <span>5.請求刪除丙方之個人資料。</span><br>
            <span>四、甲方基於上述原因而需蒐集、處理或利用丙方的個人資料，若丙方選擇拒絕提供個人資料或是填寫不完整有所隱匿時，甲方將無法提供丙方完整之系爭系統之服務，且不負相關賠償責任。倘若丙方所提供資料有虛構不實，致造成甲方遭受損害時，丙方應負相關損害賠償責任。</span><br>
            <span>五、本條聲明明確宣示個人資料管理的重要性，甲方全體員工應確實了解此聲明內容以維護丙方所有個人資料檔案之安全與永續經營。</span><br>
            <span>六、本條聲明由甲方人人共創數位科技有限公司提出，經丙方確認並同意自本契約成立時起，甲方有權依上述聲明書內容蒐集、處理或利用丙方的個人資料，丙方並可透過下列管道聯絡甲方。</span><br>
            <span>E-Mail：service@walkingcloset.co</span>
          </p>
          <div class="fs-16px">
            立契約書時間：{{ currentDate }}
          </div>
        </div>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showEditRewardInfo" title="編輯匯款資料" @back="closeEditRewardInfoModal">
        <div class="container">
          <div class="edit-reward-info-modal">
            <div class="title">匯款資料</div>
            <CustomSelectGroup labelText="銀行代號" :options="bankOptions" v-model="submitUserBankAccount.bankCode" />
            <CustomInputGroup labelText="戶名" v-model="submitUserBankAccount.accountName" />
            <CustomInputGroup labelText="帳號" v-model="submitUserBankAccount.bankAccount" />
          </div>
  
          <div class="px-2 py-3" style="background-color: #f7f7f7; color: #404040;">
            <p class="mb-0">
              請注意：每次儲存後，系統只會保留您最新的匯款資料，店家將直接查看您最新的匯款資訊。請確認資料正確後再行儲存。
            </p>
          </div>

        </div>
        <CustomModalFooter class="form-footer">
          <div class="container">
            <b-button variant="primary" block :disabled="!isBankAccountComplete" @click="submitBankData">
              <span>儲存</span>
            </b-button>

          </div>
        </CustomModalFooter>
      </FullScreenModal>
    </transition>

    <SemiOverlayModal v-model="showConfirmBargain">
      <div class="bargain-settings">
        <h2 class="fs-h2 text-center">店家確認議價中</h2>
        <div class="desc">
          <span>已收到欲重新議價的單品最低回饋金額，店家將於7日內回覆確認。</span>
        </div>
        <b-button class="bargain-btn fs-16px" variant="primary" @click="confirmBargain">
          <span>確認</span>
        </b-button>
        <b-button class="bargain-btn fs-16px" variant="outline-primary" @click="closeConfirmModal">
          <span>取消</span>
        </b-button>
      </div>
    </SemiOverlayModal>

    <SemiOverlayModal v-model="showConfirmPrice">
      <div class="container">
        <div class="bargain-settings">
          <h2 class="fs-h2 text-center">{{ orderData.event.type === 0 ? '確認報價及委託寄售。' : '確認捐贈'}}</h2>
          <div class="desc" v-if="orderData.event.type === 0">
            <span>單品上架後可能會依據販售時間或配合銷售活動優惠調整定價，實際回饋金將以最終售出定價計算。</span>
            <br>
            <span>寄件時請勿寄送不在本次申請之單品，如收到實品與照片不符合將不會上架。</span>
            <span class="warning-text">{{ orderData.invalidReturnMethod === 0 ? '未上架的單品將代捐贈給合作機構。': '未上架的單品將加收運費寄回。'}}</span>
            <br>
            <span>確認委託 </span>
            <span v-text="itemLength"></span>
            <span> 件衣物寄售嗎？</span>
          </div>
          <div class="desc" v-if="orderData.event.type === 1">
            <span>寄件時請勿寄送不在本次申請之單品，如收到實品與照片不符合將不會上架。</span>
            <span class="warning-text">未上架的單品將加收運費寄回。</span>
            <br>
            <span>確認捐贈 </span>
            <span v-text="itemLength"></span>
            <span> 件衣物嗎？</span>
          </div>
          <b-button class="bargain-btn fs-16px" variant="primary" @click="confirmPrice">
            <span>確認</span>
          </b-button>
          <b-button class="bargain-btn fs-16px" variant="outline-primary" @click="closeConfirmModal">
            <span>取消</span>
          </b-button>
        </div>

      </div>
    </SemiOverlayModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import CustomInputGroup from "@/components/commons/CustomInputGroup.vue";
import CustomSelectGroup from "@/components/commons/CustomSelectGroup.vue";
import OrderPriceCheckItem from "@/views/share/OrderPriceCheckItem.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import FullScreenModal from "@/components/FullScreenModal.vue";
import SemiOverlayModal from "@/components/SemiOverlayModal.vue";
import bankList from "@/lib/bankList";
import moment from "moment";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'HistoryOrderConfirmPriceCheck',
  data() {
    return {
      readingBusyName: 'HistoryOrderConfirmPriceCheck',

      currentTab: 'pending',

      agreeConsign: false,
      showConfirmAgreeConsign: false,
      showEditRewardInfo: false,
      contractorID: '',

      showConfirmBargain: false,
      showConfirmPrice: false,

      currentDate: moment().format('YYYY年MM月DD日'),

      bankList: bankList,
      userBankAccount: null,
      submitUserBankAccount: {
        bankCode: '',
        bankName: '',
        bankAccount: '',
        accountName: '',
      },

      init: false,
    };
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    businessData: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomInputGroup,
    CustomSelectGroup,
    SemiOverlayModal,
    FullScreenModal,
    OrderPriceCheckItem,
    CustomModalFooter,
  },
  computed: {
    showConfirmBtn() {
      if(this.orderData) {
        return this.isNeedBargain ? this.orderData.cloths.filter(item => item.confirmStatus === 0).length !== 0 : !( this.agreeConsign && this.isValidContractorID );
      }
      return true;
    },
    filteredItemList() {
      if(this.orderData) {
        return this.orderData.cloths.filter((item) => {
          // !(item.confirmStatus === 1 && item.passStatus === 2)
          return item.passStatus === 1 && (item.confirmStatus === 1 || (item.confirmStatus === 2 && item.needBargain));
        });
      } else {
        return [];
      }
    },
    itemLength() {
      return this.filteredItemList.length;
    },
    isNeedBargain() {
      if (!this.orderData || !this.orderData.cloths || !this.orderData.cloths.length) {
        return false;
      }
      return this.orderData.cloths.some(cloth => cloth.needBargain === true);
    },
    isValidContractorID() {
      const id = this.contractorID;
      const regex = /^[A-Z][1-2]\d{8}$/;
      return regex.test(id)
    },
    isBankAccountComplete() {
      return Object.values(this.submitUserBankAccount).every(value => value.trim() !== '');
    },
    bankOptions() {
      // 將 bankListData 轉換為 b-form-select 適用的格式
      return this.bankList.map(bank => ({
        value: bank.code, // 值為銀行代碼
        text: `${bank.code} - ${bank.name}`, // 顯示銀行代碼與名稱
      }));
    },
  },
  watch: {
    'submitUserBankAccount.bankCode'(newBankCode) {
      if (!newBankCode) {
        this.submitUserBankAccount.bankName = '';
      } else {
        const foundBank = bankList.find(bank => bank.code === newBankCode);

        if (foundBank) {
          this.submitUserBankAccount.bankName = foundBank.name;
        } else {
          this.submitUserBankAccount.bankName = 'bankCode Error!';
        }
      }
    }
  },
  async mounted() {
    await this.handleAsyncTask(this.readInfo);
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData']),

    async readInfo() {
      this.userBankAccount = await this.$store.dispatch('api/getUserBankAccountPromise');
      this.updateSubmitUserBankAccount();
    },
    updateSubmitUserBankAccount() {
      this.submitUserBankAccount = JSON.parse(JSON.stringify({
        bankCode: this.userBankAccount.bankCode,
        bankName: this.userBankAccount.bankName,
        bankAccount: this.userBankAccount.bankAccount,
        accountName: this.userBankAccount.accountName
      }));
    },
    async submitBankData() {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postUserBankAccountPromise', this.submitUserBankAccount);
        await this.readInfo();
        this.closeEditRewardInfoModal();
      });
    },
    async updateConfirm() {
      console.log('updateConfirm')
      await this.handleAsyncTask(this.readInfo);
    },
    checkConfirm() {
      if(this.isNeedBargain) {
        this.showConfirmBargain = true;
      } else {
        this.showConfirmPrice = true;
      }
    },
    confirmBargain() {
      this.$emit('bargain')
    },
    confirmPrice() {
      this.$emit('confirmPrice', this.contractorID)
    },
    // async sendBargain() {
    //   await this.handleAsyncTask(async () => {
    //     await this.$store.dispatch('api/postReviewOrderSendBargainPromise', this.orderData.serial);
    //   });
    //   this.$emit('close')
    // },
    openConfirmAgreeConsignModal() {
      this.showConfirmAgreeConsign = true;
    },
    closeConfirmAgreeConsignModal() {
      this.showConfirmAgreeConsign = false;
    },
    openEditRewardInfoModal() {
      this.showEditRewardInfo = true;
    },
    closeEditRewardInfoModal() {
      this.showEditRewardInfo = false;
    },
    closeConfirmModal() {
      this.showConfirmBargain = false;
      this.showConfirmPrice = false;
    },
    printContent() {
      // A4 紙張的寬高比例（寬：210mm 高：297mm）
      const a4WidthPx = 210 * 3.78; // 1mm ≈ 3.78px
      const a4HeightPx = 297 * 3.78;

      const printWindow = window.open('', '', `height=${a4HeightPx},width=${a4WidthPx}`);
      const printContent = document.querySelector('.agree-consign-modal').innerHTML;

      printWindow.document.open();
      printWindow.document.write(`
      <html>
      <head>
        <title>Print Page</title>
        <style>
          @media print {
            @page {
              size: A4; /* 設置紙張大小為 A4 */
              margin: 20mm; /* 設置頁面邊距 */
            }

            body * {
              visibility: hidden;
            }

            #print-area, #print-area * {
              visibility: visible;
            }

            #print-area {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: auto;
              margin: 0 auto;
            }

            body, html {
              font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
              font-size: 12pt; /* 设置字体大小 */
              line-height: 1.5; /* 设置行高 */
            }

            .print-btn {
              display: none;
            }
          }

          /* 非打印状态下的样式 */
          body {
            margin: 0;
            padding: 4rem 2rem;
            h2.header {
              text-align: center;
            }
          }
        </style>
      </head>
      <body>
        <div id="print-area">
          <h2 class="header">委託約定</h2>
          ${printContent}
        </div>
      </body>
      </html>
    `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-order-price-check {
  padding-bottom: 5rem;
  .price-check-desc {
    color: $typography-primary-default;
    padding: 1rem;
  }
  .price-check-info {
    padding: 0 1rem;
    hr {
      border-top: 1px solid $color-border;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .price-check-count {
    padding: 0 1rem;
    &.fs-h3 {
      margin-bottom: 0;
    }
  }
  .order-price-check-item-list {
    padding-top: 1rem;
  }
  .info {
    .fs-h3 {
      margin-bottom: 0;
    }
  }
  .agree-consign {
    margin-bottom: 1rem;
  }
  .agree-consign-link {
    cursor: pointer;
    color: $typography-text-link;
  }
  .bargain-settings {
    .fs-h2 {
      margin-bottom: .75rem;
    }
    .desc {
      margin-bottom: .75rem;
    }
    .bargain-btn {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: .75rem;
      }
    }
    .warning-text {
      color: $typography-primary-error;
    }
  }
  .reward-info {
    margin-bottom: 2.5rem;
    .title {
      font-size: $font-medium;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 1rem;
    }
    .detail {
      gap: 1rem;
      margin-bottom: 1rem;
      .name, .data {
        .item:not(:last-child) {
          margin-bottom: .5rem;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-price-check {
  .order-price-check-item-list {
    .order-price-check-item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }
    }
  }
  .agree-consign {
    .custom-control  {
      font-size: 1rem;
    }
    .custom-control-label::before {
      top: 2px;
      left: -2rem;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid $typography-secondary-default;
    }
    .custom-control-label::after {
      top: 2px;
      left: -2rem;
      width: 1.25rem;
      height: 1.25rem;
      background: 50% / 1rem 1rem no-repeat;
    }
  }
  .form-group {
    .col-form-label {
      padding-bottom: .75rem;
    }
    input.form-control {
      padding: 1rem;
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: $color-btn-disabled-secondary;
      opacity: 1;
    }
  }
  .bargain-settings {
    .btn-outline-primary {
      border: 1px solid transparent;
    }
    .btn-outline-primary:not(:disabled):hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
      border: 1px solid transparent;
    }
  }
  .agree-consign-modal {
    color: $typography-primary-default;
    padding: 1rem;
    .print-btn {
      cursor: pointer;
      position: fixed;
      top: 6px;
      right: .5rem;
      display: flex;
      padding: .5rem;
    }
  }
  .edit-reward-info-modal {
    padding: 1.5rem 1rem 5rem;
    .title {
      font-size: $font-large;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 1rem;
    }
  }
}
</style>
  