<template>
  <div class="history-order-detail">
    <div class="order-info">
      <div class="item-group">
        <div class="item fs-14px">
          <div class="name fw-bold">品項</div>
          <div class="desc">
            <span>[</span>
            <span v-text="formattedEventType"></span>
            <span>] </span>
            <span v-text="detailData.event.displayName"></span>
            <span></span>
          </div>
        </div>
        <div class="item fs-14px">
          <div class="name fw-bold">服務提供商</div>
          <div class="desc" v-text="detailData.businessAccount.name"></div>
        </div>

        <div class="item fs-14px" v-if="isStage0">
          <div class="name fw-bold">寄售件數</div>
          <div class="desc">
            <span v-text="detailData.clothAmountLimit"></span>
            <span>件以內</span>
          </div>
        </div>

        <div class="item fs-14px" v-if="isStage1">
          <div class="name fw-bold">衣物件數</div>
          <div class="desc">
            <span v-text="detailData.clothAmount"></span>
          </div>
        </div>

        <div class="item fs-14px" v-if="isStage1">
          <div class="name fw-bold">訂購人</div>
          <div class="desc">
            <span v-text="detailData.contactName"></span>
          </div>
        </div>

        <div class="item fs-14px" v-if="isStage1">
          <div class="name fw-bold">電子郵件</div>
          <div class="desc">
            <span v-text="detailData.contactEmail"></span>
          </div>
        </div>

        <div class="item fs-14px" v-if="isStage1">
          <div class="name fw-bold">付款金額</div>
          <div class="desc">
            <span v-text="formattedPrice"></span>
          </div>
        </div>

        <div class="item fs-14px" v-if="isStage1">
          <div class="name fw-bold">訂單編號</div>
          <div class="desc">
            <span v-text="detailData.id"></span>
          </div>
        </div>
      </div>
      <hr>
      <h3 class="fs-h3">注意事項</h3>
      <div class="fs-14px mb-4">
        <span>此訂單僅為通知服務提供商您欲寄售服裝，如已累積足夠寄售件數，服務提供商有權隨時停止收件，請於正式寄出衣物前再次確認廠商是否仍然收件中。</span>
      </div>
      <h3 class="fs-h3 d-flex justify-content-between" v-if="isStage0">
        <span>訂單金額</span>
        <span v-text="formattedPrice"></span>
      </h3>
    </div>
    <div class="service-info">
      <!-- <div class="service-group">
        <div class="title fs-h4">客服資訊</div>
        <div class="name fs-16px fw-bold">長腳衣櫥｜人人共創數位有限公司</div>
        <div class="desc fs-14px">Email：service@walkingcloset.co</div>
        <div class="desc fs-14px">客服專線：02-7752-2510</div>
        <div class="desc fs-14px">客服 LINE： ＠walkingcloset</div>
      </div> -->
      <div class="service-group">
        <div class="title fs-h4">店家資訊</div>
        <div class="name fs-16px fw-bold" v-text="detailData.businessAccount.name"></div>
        <div class="desc fs-14px">
          <span>地址：</span>
          <span  v-text="formattedAddress"></span>
        </div>
        <div class="desc fs-14px">
          <span>聯絡電話：</span>
          <span v-text="formattedPhone"></span>
        </div>
        <div class="desc fs-14px">
          <span>服務時間：</span>
          <span v-text="formattedServiceTime"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'HistoryOrderDetail',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
    };
  },
  props: {
    detailData: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  computed: {
    formattedEventType() {
      switch (this.detailData.event.type) {
        case 0:
          return '寄售';
        case 1:
          return '捐贈';
        default:
          return '錯誤狀態'
      }
    },
    formattedPrice() {
      return '$' + this.detailData.event.reviewPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedAddress() {
      return this.detailData.businessAccount.address || '店家未提供';
    },
    formattedPhone() {
      return this.detailData.businessAccount.phone || '店家未提供';
    },
    formattedServiceTime() {
      return this.detailData.businessAccount.serviceTime || '店家未提供';
    },
    stage() {
      if (this.detailData.status <= 2) {
        return 0;
      }
      return 1;
    },
    isStage0() {
      return this.stage === 0;
    },
    isStage1() {
      return this.stage === 1;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-order-detail {
  display: flex;
  flex-direction: column;
  @include min-height-with-modal-nav;
  .order-info, .service-info {
    padding: 1rem;
  }
  .service-info {
    flex: 1 0;
    background: $color-gray-light;
  }
  .item-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .name {
      margin-bottom: .25rem;
    }
  }
  h3.fs-h3 {
    margin-bottom: .5rem;
  }
  .mark {
    color: $typography-text-link;
    text-decoration: underline;
  }
  .service-group {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 1rem;
    }
    .title.fs-h4 {
      margin-bottom: .75rem;
    }
    .name, .desc {
      margin-bottom: .25rem;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-detail {
}
</style>
  