<template>
  <div class="history-order-transfer-check">
    <div class="container">
      <p class="top-desc">完整的寄件說明請至 Email 中查看。</p>
      <div class="transfer-progress-group">
        <div class="step">
          <div class="icon">1</div>
        </div>
        <div class="content">
          <div class="title fs-h3">打包</div>
          <div class="desc">
            <span>請放入 </span>
            <span>{{ orderData.sendClothAmount }}</span>
            <span> 件衣物與列印的寄送單</span>
          </div>
          <div><a href="#" @click.prevent="dlPdf">下載寄送單</a></div>
        </div>
      </div>
      <div class="transfer-progress-group">
        <div class="step">
          <div class="icon">2</div>
        </div>
        <div class="content">
          <div class="title fs-h3">選擇寄出方式</div>
          <b-form-select v-model="submitData.sendClothType" :options="selectOptions">
            <template #first>
              <option disabled value="">{{ emptyOptionText }}</option>
            </template>
          </b-form-select>

          <div class="mt-3">
            <div v-if="submitData.sendClothType === 0">
              <p class="mb-0"><span v-if="orderData.event.tfStoreType === 0">7-11</span><span v-if="orderData.event.tfStoreType === 1">全家便利商店</span></p>
              <p class="mb-0">收件人: {{orderData.event.tfStoreReceiver}}</p>
              <p class="mb-0">聯絡電話: {{orderData.event.tfStorePhone}}</p>
              <p class="mb-0">門市店號: {{orderData.event.tfStoreCode}}</p>
              <p class="mb-0">門市店名: {{orderData.event.tfStoreName}}</p>
            </div> 
            
            <div v-if="submitData.sendClothType === 1">
              <p class="mb-0">收件人: {{orderData.event.tfMailReceiver}}</p>
              <p class="mb-0">聯絡電話: {{orderData.event.tfMailPhone}}</p>
              <p class="mb-0">收件地址: {{orderData.event.tfMailAddress}}</p>
              <p class="mb-0">備註: {{orderData.event.tfMailPS}}</p>
            </div> 

            <div v-if="submitData.sendClothType === 2">
              <p class="mb-0">指定時間: {{orderData.event.tfDirectReceiveTime}}</p>
              <p class="mb-0">聯絡電話: {{orderData.event.tfDirectPhone}}</p>
              <p class="mb-0">收件地址: {{orderData.event.tfDirectAddress}}</p>
              <p class="mb-0">備註: {{orderData.event.tfDirectPS}}</p>
            </div> 
          </div>

        </div>
      </div>
      <div class="transfer-progress-group">
        <div class="step">
          <div class="icon">3</div>
        </div>
        <div class="content">
          <div class="title fs-h3">寄出回報</div>
          <div class="desc">
            <span>如無寄件收據請直接點選最下方「完成寄出」按鈕。</span>
          </div>
          <b-form-group class="form-group-transfer-date">
            <template v-slot:label>
              <div class="fs-16px">
                <span>寄出時間</span>
              </div>
            </template>
            <b-form-input id="input-transfer-date" type="date" v-model="submitData.sendClothDate"></b-form-input>
          </b-form-group>
          <div class="images-group">
            <div class="collection-image" v-if="submitData.sendClothCodeImg">
              <img :src="submitData.sendClothCodeImg" alt="receipt image">
            </div>
            <CropImageButton class="w-100" @uploaded="setDefectiveImageData">
              <b-button variant="outline-primary" block v-if="!submitData.sendClothCodeImg">
                <span>上傳寄件收據</span>
              </b-button>
              <b-button class="re-upload" variant="outline-primary" v-if="submitData.sendClothCodeImg">
                <Icon name="Vertical_switch_light" size="24"></Icon>
                <span>重新上傳</span>
              </b-button>
            </CropImageButton>
          </div>
        </div>
      </div>
    </div>

    <CustomModalFooter class="form-footer">
      <div class="container">
        <b-button variant="primary" block :disabled="showConfirmBtn" @click="confirmTransfer">
          <span>完成寄出</span>
        </b-button>

      </div>
    </CustomModalFooter>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import CropImageButton from '@/components/cropImage/CropImageButton.vue';

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'HistoryOrderTransferCheck',
  data() {
    return {
      readingBusyName: 'HistoryOrderTransferCheck',

      submitData: {
        serial: -1,
        sendClothType: '',
        sendClothCode: '',
        sendClothDate: '',
        sendClothCodeImg: '',
      },
    };
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomModalFooter,
    CropImageButton,
  },
  computed: {
    showConfirmBtn() {
      return !(this.submitData.serial !== -1 && this.submitData.sendClothType !== '');
    },
    selectOptions() {
      const options = [];

      if (this.orderData.event.transferStore) {
        options.push({ text: "便利商店-店到店", value: 0 });
      }

      if (this.orderData.event.transferMail) {
        options.push({ text: "郵局", value: 1 });
      }

      if (this.orderData.event.transferDirect) {
        options.push({ text: "親送", value: 2 });
      }

      return options;
    },
    emptyOptionText() {
      return this.selectOptions.length > 0 ? "請選擇" : "無可用選項";
    },
  },
  watch: {
  },
  async mounted() {
    this.submitData.serial = this.orderData.serial;
    this.submitData.sendClothDate = moment().format('YYYY-MM-DD');
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData']),

    async confirmTransfer() {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewOrderSendTransferredPromise', this.submitData);
      });
      await this.refreshHistoryList();
      this.$emit('close');
    },
    setDefectiveImageData(data) {
      this.submitData.sendClothCodeImg = data.url;
    },
    async dlPdf() {
      await this.$store.dispatch('api/getReviewOrderPDFPromise', {serial: this.orderData.serial, filename: `${this.orderData.id}_衣物寄送單.pdf`});
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.top-desc {
  color: $typography-secondary-default;
}
.history-order-transfer-check {
  padding: 1.5rem 2rem 5rem 1rem;
  .transfer-progress-group {
    display: flex;
    align-items: stretch;
    &:not(:last-child) {
      .step {
        position: relative;
        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          display: block;
          content: '';
          width: 2px;
          height: 100%;
          background: $color-orange-light;
          transform: translateX(-50%);
        }
      }
    }
    .step {
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-orange-light;
        width: 2rem;
        height: 2rem;
        font-size: $font-small;
        font-weight: $font-weight-semi-bold;
        border-radius: 100%;
      }
    }
    .content {
      padding-top: .125rem;
      padding-left: 1rem;
      padding-bottom: 1.5rem;
      .desc {
        color: $typography-secondary-default;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-transfer-check {
  .custom-select {
    min-width: 11.5rem;
    padding: 1rem;
  }
  input.form-control {
    padding: 1rem;
  }
  .form-group-transfer-date {
    margin-top: 1.5rem;
    .fs-16px {
      margin-bottom: .25rem;
    }
  }
  .images-group {
    display: flex;
    align-items: end;
    gap: 1rem;
    margin-top: 1.5rem;
    .collection-image {
      flex: 0 0 auto;
      width: 109px;
      height: 109px;
      border-radius: .5rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .btn.re-upload {
      border-color: $color-border;
      padding: .5rem 1.5rem;
      svg {
        margin-right: .5rem;
      }
    }
  }
}
</style>
  