<template>
  <transition name="fade">
    <FullScreenModal class="submit-clothing-data" v-if="modalShow" :btn-type="btnType" :title="stepName" @back="backClicked">
      <ValidationObserver ref="observer" tag="div">
        <div class="container">

          <div class="info-block" v-if="submitStep === 2">
            <div class="finish-card">
              <Icon class="finish-image" name="Submit_success" size="91" />
              <h2 class="fs-h2">服務加購完成</h2>
              <div class="fs-16px">已收到你的衣物資料，店家將於7日內回覆</div>
            </div>
            <hr>
            <h3 class="fs-18px pt-3">訂單資訊</h3>
          </div>
          <div class="info-block">
            <div class="submit-input" v-if="submitStep === 0">
              <h3 class="fs-18px">聯絡資訊</h3>
              <ValidationProvider ref="nameValidation" tag="div" rules="required" v-slot="{ errors }">
                <CustomInputGroup labelText="真實姓名" :descText="formData.event.type === 0 ? '請提供與未來回饋金提領帳戶銀行戶名相同之姓名' : ''" :errorText="errors.length > 0 ? errors[0] : ''" v-model="submitData.name" />
              </ValidationProvider>
  
              <ValidationProvider ref="phoneValidation" tag="div" rules="required" v-slot="{ errors }">
                <CustomInputGroup inputType="tel" labelText="聯絡電話" :errorText="errors.length > 0 ? errors[0] : ''" v-model="submitData.phone" />
              </ValidationProvider>
  
              <ValidationProvider ref="emailValidation" tag="div" rules="required|email" v-slot="{ errors }">
                <CustomInputGroup inputType="email" labelText="電子郵件" :errorText="errors.length > 0 ? errors[0] : ''" v-model="submitData.email" />
              </ValidationProvider>
  
              <CustomInputGroup labelText="Instagram ID" v-model="submitData.instagram" />
            </div>
  
            <div class="order-info" v-if="submitStep === 1 || submitStep === 2">
              <div class="order-info-group">
                <div class="fs-14px fw-bold">品項</div>
                <div class="fs-14px">
                  <span>[</span>
                  <span v-text="formattedEventType"></span>
                  <span>] </span>
                  <span v-text="formData.event.displayName"></span>
                </div>
              </div>
              <div class="order-info-group">
                <div class="fs-14px fw-bold">服務提供商</div>
                <div class="fs-14px" v-text="businessAccount.name"></div>
              </div>
              <div class="order-info-group">
                <div class="fs-14px fw-bold">衣物件數</div>
                <div class="fs-14px">
                  <span v-text="formData.clothAmount"></span>
                  <span>件</span>
                </div>
              </div>
              <div class="order-info-group">
                <div class="fs-14px fw-bold">訂購人</div>
                <div class="fs-14px" v-text="submitData.name"></div>
              </div>
              <div class="order-info-group">
                <div class="fs-14px fw-bold">電子郵件</div>
                <div class="fs-14px" v-text="submitData.email"></div>
              </div>
              <div class="order-info-group">
                <div class="fs-14px fw-bold">付款金額</div>
                <div class="fs-14px" v-text="formattedPrice"></div>
              </div>
            </div>
  
            <div class="service-msg fs-14px">
              <p>
                請留意本服務為線上服務，完成購買後可立即使用。如有發票將以電子發票方式寄至您的信箱。
              </p>
              <p>
                如遇問題可前往本站「聯絡我們」頁面提交表單，或以使用以下方式聯繫我們：
              </p>
              <p>
                客服信箱：service@walkingcloset.co<br>
                客服LINE： ＠walkingcloset<br>
                客服專線：02-7752-2510
              </p>
            </div>
            <hr>
            <h3 class="fs-18px">
              <span>{{ formData.event.type === 0 ? '寄售單品 ' : '捐贈單品 ' }}</span>
              <span v-text="formData.cloths.length">18</span>
              <span>件</span>
            </h3>
          </div>
        </div>

        <div class="single-item-clothes-list">
          <div class="container">
            <SingleItemClothesCard v-for="(item, index) in formData.cloths" :key="index" :cardData="item" :hideEditBtn="true" :hideRemoveBtn="submitStep === 2"/>
          </div>
        </div>

        <CustomModalFooter class="submit-footer">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center flex-nowrap">
              <div class="event-group d-flex" v-if="submitStep !== 2">
                <div class="event-image">
                  <img :src="formData.event.displayImg" alt="">
                </div>
                <div class="event-info">
                  <div class="fs-18px fw-bold">
                    <span>[</span>
                    <span v-text="formattedEventType"></span>
                    <span>] </span>
                    <span v-text="formData.event.displayName"></span>
                  </div>
                  <div class="fs-16px fw-bold" v-text="formattedPrice"></div>
                </div>
              </div>
              <b-button v-if="submitStep !== 2" class="submit-btn" variant="primary" @click="nextStep">
                <span v-text="submitBtnText"></span>
              </b-button>
              <b-button v-if="submitStep === 2" block variant="primary" @click="nextStep">
                <span>關閉</span>
              </b-button>

            </div>
          </div>
        </CustomModalFooter>
      </ValidationObserver>
    </FullScreenModal>
  </transition>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import {mapState} from 'vuex';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import FullScreenModal from "@/components/FullScreenModal.vue";
import CustomInputGroup from "@/components/commons/CustomInputGroup.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import SingleItemClothesCard from "@/views/share/SingleItemClothesCard.vue";

extend('required', {
  ...required,
  message: '此為必填欄位。',
});
extend('email', {
  ...email,
  message: '請輸入正確的email格式。',
});

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'SubmitClothingData',
  data() {
    return {
      readingBusyName: 'SubmitClothingData',

      submitStep: 0,
      submitData: {
        serial: this.formData.serial,
        name: '',
        phone: '',
        email: '',
        instagram: '',
      },
    };
  },
  props: {
    value: {
      type: Boolean,
    },
    formData: {
      type: Object,
      required: true,
    },
    businessAccount: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    SingleItemClothesCard,
    FullScreenModal,
    CustomInputGroup,
    CustomModalFooter,
  },
  computed: {
    ...mapState(['clothCategories']),

    modalShow: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    stepName() {
      switch (this.submitStep) {
        case 0:
          return '送出衣物資料';
        case 1:
          return '確認訂單內容';
        case 2:
          return '訂單完成';
        default:
          return '錯誤狀態';
      }
    },
    submitBtnText() {
      switch (this.submitStep) {
        case 0:
          return '送出';
        case 1:
          return '確認';
        default:
          return '';
      }
    },
    btnType() {
      switch (this.submitStep) {
        case 2:
          return 'close';
        default:
          return '';
      }
    },
    formattedEventType() {
      switch (this.formData.event.type) {
        case 0:
          return '寄售';
        case 1:
          return '捐贈';
        default:
          return '錯誤狀態'
      }
    },
    formattedPrice() {
      return '$' + this.formData.event.reviewPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    closeSubmitClothingDataModal() {
      this.modalShow = false;
    },
    backClicked() {
      switch (this.submitStep) {
        case 0:
          this.closeSubmitClothingDataModal();
          break;
        case 1:
          this.submitStep = 0;
          break;
        case 2:
          this.$emit('submitSuccess');
          break;
        default:
          console.log('something wrong!');
      }
    },
    async nextStep() {
      switch (this.submitStep) {
        case 0:
          this.checkError();
          break;
        case 1:
          await this.handleAsyncTask(async () => {
            const data = await this.$store.dispatch('api/postReviewOrderSendInfoPromise', this.submitData);
            console.log(data)
          });
          this.submitStep = 2;
          break;
        case 2:
          this.$emit('submitSuccess');
          break;
        default:
          console.log('something wrong!');
      }
    },
    checkError() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          console.log('Validation successful');
          this.submitStep = 1;
        } else {
          console.log('Validation failed');
          this.scrollToError();
        }
      });
    },
    scrollToError() {
      for (const refName in this.$refs) {
        const validationProvider = this.$refs[refName];
        if (validationProvider.errors.length > 0) {
          validationProvider.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.submit-clothing-data {
  .info-block {
    padding: 0 1rem;
  }
  h3.fs-18px {
    margin-bottom: 1rem;
  }
  .service-msg {
    color: $typography-primary-default;
    p:last-child {
      margin-bottom: 0;
    }
  }
  hr {
    border-top: .5px solid #E9E9E9;
    margin: 1rem 0;
  }
  .submit-footer {
    .submit-btn {
      flex: 0 0 150px;
      width: 150px;
    }
  }
  .event-group {
    flex: 0 0 calc(100% - 150px - 32px);
    width: calc(100% - 150px - 32px);
    .event-image {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      border-radius: .375rem;
      border: .5px solid $color-border;
      overflow: hidden;
      margin-right: .875rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .event-info {
      flex: 0 0 calc(100% - 28px);
      width: calc(100% - 28px);
      .fs-18px {
        width: 100%;
        line-height: 27px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: .875rem;
      }
      .fs-16px {
        line-height: 21px;
      }
    }
  }
  .order-info {
    .order-info-group {
      margin-bottom: 1rem;
      .fw-bold {
        margin-bottom: .25rem;
      }
    }
  }
  .finish-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;
    .finish-image, h2.fs-h2 {
      margin-bottom: .25rem;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.submit-clothing-data {
  .box-body {
    padding: 1rem 0 5rem;
  }
}
</style>
  